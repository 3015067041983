@import "../../assets/scss/variables.scss";

	.question-wrapper {
	height: 100vh;
	display: table;
	width: 100%;
	height: calc(100vh - 160px);
	position: relative;
	display: inline-block;
	.imgTop {
		position: absolute;
		width: 500px;
		right: 0;
		top: -60px;
		z-index: -1;
	}
	.imgBottom {
		position: absolute;
		width: 300px;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	.imgVirus {
		position: absolute;
		width: 500px;
		right: 0;
		bottom: -50px;
		height: 350px;
		z-index: -1;
	}
	.box {
		background: #ffffff;
		box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
  		-moz-box-shadow:-7px 11px 28px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		width: 1270px;
		// height: auto;
		height: calc(100vh - 220px);
		margin: 0 auto;
		position: relative;
		// top: 110px;
		text-align: center;
		padding: 0;
		top: 32px;
		.top-bar {
			background: #4355cf;
			border-radius: 10px 10px 0px 0px;
			color: #ffffff;
			padding: 10px 0px;
		}
		.box-info {
			border: 1.5px solid #ffd54e;
			border-radius: 10px;
			width: 60%;
			margin: 0 auto;
			top: 100px;
			font-size: 16px;
			font-weight: 500;
			padding: 15px 10px;
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}
		.scroll-wrapper {
			position: absolute;
			width: 100%;
			height: 85%;
			// overflow: hidden;
			// overflow-y: scroll;
			  position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 85%;
			.content-box {
				// height: 100%;
				// position: relative;
				// overflow-y: scroll;
				height: 100%;
        position: relative;
        padding-top: 45px;
        width: 100%;
        float: left;
        overflow: hidden;
				.right-column {
					// float: right;
					// width: 90%;
					// height: 100%;
					// overflow: auto;
					    height: 100%;
          width: 100%;
          position: relative;
		  right: -15px;
		  overflow: hidden;
					.right-content {
						// position: absolute;
						// bottom: 0;
						// width: 90%;
						// top: auto;
						// max-height:100% ;
						 padding: 0 10%;
            width: 100%;
            float: left;
            position: absolute;
            left: 0;
            bottom: 0;
					 &.addpostion {
              position: relative;
			}
			 div,
            span,
            section {
              width: 100%;
              float: left;
              display: block;
            }
						.ques-Span {
							display: table;
							height: auto;
							background: red;
						}
						.answer-wrapper {
							line-height: 45px;
							margin: 20px 0;
							display: table;
							.question1 {
								display: table;
							}
							.answer {
								background: #4355cf;
								color: #ffffff;
								box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
								-webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  								-moz-box-shadow:0px 4px 12px rgba(0, 0, 0, 0.25);
								border-radius: 15px 15px 0px 15px;
								width: 50px;
								height: 45px;
								position: absolute;
								right: 50px;
							}
						}
					}
				}
				.content-wrapper {
					position: relative;
					top: 30px;
				}
				.content {
					background: #ffffff;
					box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
					-webkit-box-shadow:0px 4px 12px rgba(0, 0, 0, 0.25);
  					-moz-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
					border-radius: 15px 15px 15px 0px;
					text-align: left;
					clear: both;
					margin-top: 30px;
					padding: 10px;
					max-width: 600px;
					display: table;
					margin-bottom: 20px;
					z-index: 99;
					word-break: break-word;
					ul {
						list-style: none;
						padding: 0;
						margin: 5px;
						li::before {
							content: "\2022";
							color: #4355cf;
							font-weight: bold;
							display: inline-block;
							width: 1em;
						}
						li {
							text-align: left;
							line-height: 30px;
						}
					}
				}
			}
			.left-column {
				// position: absolute;
				// bottom: 0;
				img {
					position: absolute;
					bottom: 20px;
					margin: 0 auto;
					left: 20px;
				}
			}
		}
		.bottom-wrapper {
			padding: 0px 20px 20px;
			text-align: right;
			position: absolute;
			bottom: 0;
			width: 100%;
			img {
				width: 40px;
			}
			.bottom-btn {
				background: #ffffff;
				box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
				-webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
 				-moz-box-shadow:0px 4px 12px rgba(0, 0, 0, 0.25);
				border-radius: 15px 15px 0px 15px;
				width: 50px;
				height: 45px;
				margin-right: 20px;
				border: 1px solid transparent;
			}
		}
	}
	//  ::-webkit-scrollbar {
	// 	display: none;
	// }
}

@media only screen and (min-width:1281px) and (max-width:1500px) {
	.question-wrapper {
		.box {
			max-width: 1050px;
		}
	}
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
	.question-wrapper {
		.box {
			max-width: 950px;
		}
	}
}

@media only screen and (min-width:993px) and (max-width:1024px) {
	.question-wrapper {
		.box {
			max-width: 850px;
		}
	}
}

@media only screen and (min-width:768px) and (max-width:992px) {
	.question-wrapper {
		.box {
			max-width: 750px;
			.scroll-wrapper{
				.content-box {
					.content{
						    max-width: 500px;
					}
				}
			}
		}
		.imgVirus {
			width: 370px !important;
		}
	}
}

@media only screen and (min-width:660px) and (max-width:767px) {
	.question-wrapper {
		.box {
			max-width: 600px;
			.scroll-wrapper {
				.content-box {
					.content {
						max-width: 350px;
					}
				}
				.left-column {
					img {
						width: 30px;
					}
				}
			}
		}
		.imgTop {
			width: 450px !important;
		}
		.imgVirus {
			width: 370px !important;
		}
	}
}

@media only screen and (min-width:500px) and (max-width:661px) {
	.question-wrapper {
		.box {
			max-width: 480px;
			.scroll-wrapper {
				.content-box {
					.right-column {
						width: 95%;
					}
					.content {
						max-width: 330px;
					}
				}
				.left-column {
					img {
						width: 30px;
					}
				}
			}
		}
		.imgTop {
			width: 450px !important;
		}
		.imgVirus {
			width: 250px !important;
			height: 250px !important;
			bottom: -35px !important;
		}
		.imgBottom {
			width: 250px !important;
		}
	}
}

@media only screen and (min-width:400px) and (max-width:501px) {
	.question-wrapper {
		  height: calc(100vh - 98px);
		.box {
			max-width: 390px;
			 height: calc(100vh - 160px);
			top:10px;
			.scroll-wrapper {
				.content-box {
					.right-column {
						width: 95%;
					}
					.content {
						max-width: 280px;

					}
				}
				.left-column {
					img {
						width: 30px;
					}
				}
			}
			.scroll-wrapper{
				        height: calc(100vh - 320px);
			}
		}
		.imgTop {
			width: 400px !important;
		}
		.imgVirus {
			width: 250px !important;
			height: 250px !important;
			bottom: -35px !important;
		}
		.imgBottom {
			width: 175px !important;
		}
	}
}

@media only screen and (min-width:360px) and (max-width:401px) {
	.question-wrapper {
		  
		.box {
			max-width: 340px;
			height: calc(100vh - 195px);
			top:10px;
			.scroll-wrapper {
				max-height: 100%;
				  height: calc(100vh - 240px);
				.content-box {
					.right-column {
						width: 83%;
						right: -26px;
						
					}
					.content {
						max-width: 220px;
						margin-bottom: 0;
					}
					.right-content{
						margin-bottom: 20px;
							    display: grid;
					}
				}
				.left-column {
					img {
						width: 30px;
					}
				}
			}
			
			.bottom-wrapper{
					padding: 10px 20px 10px;
					// background: green;
					.bottom-btn{
						    width: 48px;
							height: 43px;
							    padding: 0;
					}
			}
			
		}
		.imgTop {
			width: 175px !important;
		}
		.imgVirus {
			width: 200px !important;
			height: 200px !important;
			bottom: -25px !important;
		}
		.imgBottom {
			width: 180px !important;
		}
	}
}