@import "../../assets/scss/variables.scss";
.e-pass-main-wrapper {
  height: 100vh;
  display: table;
  width: 100%;
  height: calc(100vh - 160px);
  position: relative;
  display: inline-block;
  .imgTop {
    position: absolute;
    width: 500px;
    right: 0;
    top: -60px;
    z-index: -1;
  }
  .imgBottom {
    position: absolute;
    width: 300px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .imgVirus {
    position: absolute;
    width: 500px;
    right: 0;
    bottom: -50px;
    height: 350px;
    z-index: -1;
  }
  .main-Section {
      display: -webkit-flex;
      display: -moz-box; 
      display: -webkit-box;
      display: -ms-flexbox; 
    display: flex;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    .box {
      background: #ffffff;
      box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 1270px;
      margin: 0 auto;
      text-align: center;
      height: calc(100vh - 250px);
      // overflow: hidden;
      // overflow-y: auto;
      // left: 0;
      // right: 0;
      position: relative;
      .top-bar {
        background: #4355cf;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        padding: 10px 0px;
        position: relative;
            height: 46px;
        .cancel {
          cursor: pointer;
        }
        .cancel::after {
          content: "";
          width: 30px;
          height: 30px;
          background: transparent url("../../assets/icons/cancel.svg") no-repeat
            3px 0px;
          left: 5px;
          position: absolute;
          display: inline-block;
        }
      }
      .bottom-bar {
        background: #4355cf;
        border-radius: 0 0 10px 10px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        display: table;
        height: 50px;
        justify-content: center;
         display: flex;
         align-items: center;
         position: absolute;
         bottom: 0;
         right: 0;
         left: 0;
      }
      .main-ContentSection{
        height: calc(100vh - 346px);
      display: inline-block;
        width: 100%;
          overflow: hidden;
      overflow-y: auto;
      left: 0;
      right: 0;
      .content {
        margin: 0 auto;
        position: relative;
        height: 480px;
        width: 310px;
        background: transparent url("../../assets/icons/e-pass-background.svg");
        background-repeat: no-repeat;
        background-position: center top;
        .content-e-pass {
          position: relative;
          text-align: center;
          top: 35px;
          margin: 0 auto;
          width: 83%;
          left: 5px;
          .e-pass-title-1 {
            font-size: 33px;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-weight: 600;
          }
          .e-pass-title-2 {
            font-size: 28px;
            text-transform: uppercase;
            margin-top: 15px;
            font-weight: 600;
          }
          .e-pass-title-3 {
            font-size: 23px;
            text-transform: uppercase;
            margin-top: 10px;
            font-weight: 600;
          }
          .e-pass-title-4 {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
          }
          .e-pass-note {
            margin: 0 auto;
            text-align: justify;
            margin-top: 30px;
            padding: 0 15px;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
          }
          .e-pass-validity {
            margin: 0 auto;
            width: 60%;
            margin-top: 70px;
            font-size: 16px;
            text-align: center;
          }
        }
      }
      button {
        width: 326px;
        height: 60px;
        margin: 0 auto;
        background: #ffc008;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 20px;
        line-height: 60px;
        border: none;
        font-size: 18px;
        font-weight: 600;
      }
    }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        max-width: 1200px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        max-width: 920px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        max-width: 850px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        max-width: 720px;
        .img-wrapper {
          width: 200px;
        }
        .text-wrapper {
          width: 200px;
        }
      }
    }
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 660px) and (max-width: 767px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        max-width: 580px;
        .img-wrapper {
          width: 170px;
        }
        .text-wrapper {
          width: 170px;
        }
      }
    }
    .imgTop {
      width: 450px !important;
    }
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 661px) {
  .e-pass-main-wrapper {
    .main-Section {
      .box {
        .inner-wrapper {
          padding: 80px 20px;
        }
        max-width: 480px;
        .img-wrapper {
          width: 120px;
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
        .text-wrapper {
          width: 120px;
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .imgTop {
      width: 450px !important;
    }
    .imgVirus {
      width: 250px !important;
      height: 250px !important;
      bottom: -35px !important;
    }
    .imgBottom {
      width: 250px !important;
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 501px) {
  .e-pass-main-wrapper {
     max-height: calc(100vh - 170px) !important;
    .main-Section {
      .box {
        height: calc(100vh - 200px) !important;
                .main-ContentSection {
     height: calc(100vh - 316px);
  }
        .inner-wrapper {
          padding: 80px 20px;
        }
        max-width: 370px;
        .img-wrapper {
          width: 80px;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
          }
        }
        .text-wrapper {
          width: 80px;
          margin-right: 30px;
          &.last-child {
            margin-right: 0 !important;
          }
          div {
            font-size: 14px;
            width: auto;
          }
        }
      }
    }
    .imgVirus {
      width: 250px !important;
      height: 250px !important;
      bottom: -35px !important;
    }
    .imgBottom {
      width: 250px !important;
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 401px) {
  .e-pass-main-wrapper {
      max-height: calc(100vh - 170px) !important;
    .main-Section {
      .box {
        height: calc(100vh - 200px) !important;
              .main-ContentSection {
    height: calc(100vh - 316px);
  }
        .inner-wrapper {
          padding: 80px 20px;
        }
        max-width: 340px;
        .img-wrapper {
          width: 80px;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 100%;
          }
        }
        .text-wrapper {
          width: 80px;
          margin-right: 30px;
          &.last-child {
            margin-right: 0 !important;
          }
          div {
            font-size: 14px;
            width: auto;
          }
        }
      }
    }
    .imgTop {
      width: 320px !important;
    }
    .imgVirus {
      width: 200px !important;
      height: 200px !important;
      bottom: -25px !important;
    }
    .imgBottom {
      width: 180px !important;
    }
  }
}
