@import "../../assets/scss/variables.scss";

.LoaderMain {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .LoaderCircle {
    position: relative;
    display: block;

    img {
      width: 150px;
      -moz-animation: spinoffPulse 1.2s infinite linear;
      -ms-animation: spinoffPulse 1.2s infinite linear;
      -o-animation: spinoffPulse 1.2s infinite linear;
      -webkit-animation: spinoffPulse 1.2s infinite linear;
      animation: spinoffPulse 1.2s infinite linear;
    }

    @-moz-keyframes spinoffPulse {
      0% {
        -moz-transform: rotate(0deg);
      }

      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-ms-keyframes spinoffPulse {
      0% {
        -moz-transform: rotate(0deg);
      }

      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-o-keyframes spinoffPulse {
      0% {
        -moz-transform: rotate(0deg);
      }

      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spinoffPulse {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spinoffPulse {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: 1023px) {
  .LoaderMain {
    .LoaderCircle {
      img {
        width: 100px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .LoaderMain {
    .LoaderCircle {
      img {
        width: 110px;
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .LoaderMain {
    .LoaderCircle {
      img {
        width: 130px;
      }
    }
  }
}
