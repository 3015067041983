@import "../../assets/scss/variables.scss";
.main-wrapper {
  height: 100vh;
  display: table;
  width: 100%;
  height: calc(100vh - 160px);
  // background-image: url('../../assets/images/bg.png');
  display: inline-block;
  position: relative;
  .imgTop {
    position: absolute;
    width: 500px;
    right: 0;
    top: -47px;
    z-index: -1;
  }
  .imgBottom {
    position: absolute;
    width: 300px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .imgVirus {
    position: absolute;
    width: 500px;
    right: 0;
    bottom: -50px;
    height: 350px;
    z-index: -1;
  }
  .main-Section {
    display: -webkit-flex;
      display: -moz-box; 
      display: -webkit-box;
      display: -ms-flexbox; 
    display: flex;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    .box-Section {
      background: #ffffff;
	  box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
	  -webkit-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
  	  -moz-box-shadow:-7px 11px 28px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 627px;
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      height: auto;
      .back {
        text-align: left;
        min-height: 30px;
        img {
          cursor: pointer;
        }
      }
      .title {
        font-size: 30px;
        font-weight: 600;
      }
      .img-wrapper {
        margin-top: 30px;
        display: inline-block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        img {
          margin-bottom: 15px;
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
        .active-img {
          cursor: default;
        }
        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .patient-logo {
		// margin-right: 100px;
		background-color: #C6C6C6;
	  }
	   .staff-logo {
		background-color: #C6C6C6;
      }
      button {
        margin: 0 auto;
        display: block;
        margin-top: 25px;
        background: #ebebeb;
        border-radius: 4px;
        border: none;
        width: 120px;
        height: 40px;
      }
      .active {
        background-color: #ffc008;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-wrapper {
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 660px) and (max-width: 767px) {
  .main-wrapper {
    .imgTop {
      width: 450px !important;
    }
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 661px) {
  .main-wrapper {
    .box-Section {
      width: 480px !important;
    }
    .title {
      font-size: 30px !important;
    }
    .imgTop {
      width: 450px !important;
    }
    .imgVirus {
      width: 250px !important;
      height: 250px !important;
      bottom: -35px !important;
    }
    .imgBottom {
      width: 250px !important;
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 501px) {
  .main-wrapper {
    max-height: calc(100vh - 170px) !important;
    .box-Section {
      width: 380px !important;
      height: calc(100vh - 200px) !important;
    }
    .img-wrapper {
      width: 100px !important;
      height: 100px !important;
    }
    .back {
      padding-left: 20px;
      min-height: 30px;
    }
    .title {
      font-size: 25px !important;
      margin-top: 35px;
    }
    .imgTop {
      width: 400px !important;
    }
    .imgVirus {
      width: 250px !important;
      height: 250px !important;
      bottom: -35px !important;
    }
    .imgBottom {
      width: 250px !important;
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 401px) {
  .main-wrapper {
     max-height: calc(100vh - 170px) !important;
    .box-Section {
      width: 340px !important;
      height: calc(100vh - 200px) !important;
    }
    .img-wrapper {
      width: 100px !important;
      height: 100px !important;
    }
    .back {
      padding-left: 20px;
    }
    .title {
      font-size: 25px !important;
          margin-top: 35px;
    }
    .imgTop {
      width: 320px !important;
    }
    .imgVirus {
      width: 200px !important;
      height: 200px !important;
      bottom: -25px !important;
    }
    .imgBottom {
      width: 180px !important;
    }
  }
}
