@import "../../assets/scss/variables.scss";
.main-wrapper {
  height: 100vh;
  display: table;
  width: 100%;
  height: calc(100vh - 160px);
  position: relative;
  display: inline-block;
  .imgTop {
    position: absolute;
    width: 500px;
    right: 0;
    top: -60px;
    z-index: -1;
  }
  .imgBottom {
    position: absolute;
    width: 300px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .imgVirus {
    position: absolute;
    width: 500px;
    right: 0;
    bottom: -50px;
    height: 350px;
    z-index: -1;
  }
  .main-Section {
    display: -webkit-flex;
      display: -moz-box; 
      display: -webkit-box;
      display: -ms-flexbox; 
    display: flex;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    .box-Dashboard {
      background: #ffffff;
      box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 627px;
      margin: 0 auto;
      padding: 20px;
      height: auto;
      max-height: calc(100vh - 290px);
      overflow: hidden;
      overflow-y: auto;
      left: 0;
      right: 0;
      .logo-wrapper {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        .logo {
          margin: 0 auto;
          position: relative;
        }
      }
      .content {
        margin: 0 auto;
        position: relative;
        word-wrap: break-word;
        text-align: center;
        padding: 30px;
        font-size: 18px;
        p {
          font-weight: 600;
          font-size: 18px;
        }
        button {
          display: block;
          margin: 0 auto;
          border-radius: 4px;
          width: 326px;
          height: 60px;
          border: none;
          margin-top: 55px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          .logoImg {
            width: 25px;
            height: 25px;
            margin-left: 15px;
          }
        }
        .button-Css {
          background: #ebebeb;
          font-weight: 600;
          font-size: 18px;
          &:hover {
            background: #ffc008;
          }
          &:last-child {
            margin-top: 32px;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1500px) {
  .main-wrapper {
    .box-Dashboard {
      overflow-y: scroll !important;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .main-wrapper {
    .main-Section {
      .box-Dashboard {
        width: 530px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-wrapper {
    .main-Section {
      .box-Dashboard {
        width: 480px !important;
      }
    }
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 660px) and (max-width: 767px) {
  .main-wrapper {
    .box-Dashboard {
      width: 480px !important;
    }
    .imgTop {
      width: 450px !important;
    }
    .imgVirus {
      width: 370px !important;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 661px) {
  .main-wrapper {
    .box-Dashboard {
      width: 480px !important;
    }
    .logo-wrapper {
      // margin-bottom: 0px !important;
    }
    .logo {
      // width: 150px !important;
    }
  }
  .imgTop {
    width: 450px !important;
  }
  .imgVirus {
    width: 250px !important;
    height: 250px !important;
    bottom: -35px !important;
  }
  .imgBottom {
    width: 250px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 501px) {
  .main-wrapper {
    max-height: calc(100vh - 170px) !important;
    .box-Dashboard {
      width: 380px !important;
      padding: 30px 0px !important;
      max-height: calc(100vh - 200px) !important;
    }
    .logo-wrapper {
      margin-bottom: 0px !important;
    }
    .logo {
      width: 150px !important;
    }
    .imgTop {
      width: 400px !important;
    }
    .imgVirus {
      width: 250px !important;
      height: 250px !important;
      bottom: -35px !important;
    }
    .imgBottom {
      width: 250px !important;
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 401px) {
  .main-wrapper {
    max-height: calc(100vh - 170px) !important;
    .box-Dashboard {
      width: 340px !important;
      padding: 30px 0px !important;
      max-height: calc(100vh - 200px) !important;
      // margin-top: 10px;
      // margin-bottom: 10px;
    }
    .content {
      button {
        width: 275px !important;
      }
    }
    .logo-wrapper {
      margin-bottom: 0px !important;
    }
    .logo {
      width: 150px !important;
    }
    .imgTop {
      width: 320px !important;
    }
    .imgVirus {
      width: 200px !important;
      height: 200px !important;
      bottom: -25px !important;
    }
    .imgBottom {
      width: 180px !important;
    }
  }
}
