.toast-div {
  display: flex;
  justify-content: space-between;
  .msg-toast {
    align-items: center;
    display: flex;
    img{
        margin-right: 5px;
    }
  }
  button {
    border: 0;
    color: #4e7dfe;
  }
}
