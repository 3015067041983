@import "../../assets/scss/variables.scss";
.cover-main-wrapper {
	height: 100vh;
	display: table;
	width: 100%;
	height: calc(100vh - 160px);
	position: relative;
	display: inline-block;
	.imgTop {
		position: absolute;
		width: 500px;
		right: 0;
		top: -60px;
		z-index: -1;
	}
	.imgBottom {
		position: absolute;
		width: 300px;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	.imgVirus {
		position: absolute;
		width: 500px;
		right: 0;
		bottom: -50px;
		height: 350px;
		z-index: -1;
	}
	.main-Section {
		  display: -webkit-flex;
      display: -moz-box; 
      display: -webkit-box;
      display: -ms-flexbox; 
    display: flex;
		text-align: center;
		margin: 0 auto;
		height: 100%;
		-webkit-align-items: center;
		align-items: center;
		// -webkit-justify-content: center;
		// justify-content: center;
		.box {
			background: #ffffff;
			box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
			-webkit-box-shadow: -7px 11px 28px rgba(0, 0, 0, 0.25);
 			-moz-box-shadow:-7px 11px 28px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			width: 100%;
			max-width: 1270px;
			margin: 0 auto;
			position: relative;
			text-align: center;
			height: auto;
			max-height: calc(100vh - 200px) !important;
			overflow: hidden;
			overflow-y: auto;
			left: 0;
			right: 0;
			.inner-wrapper {
				padding: 80px 30px;
			}
			.top-bar {
				background: #4355cf;
				border-radius: 10px 10px 0px 0px;
				color: #ffffff;
				padding: 10px 0px;

				.cancel {
					cursor: pointer;
				}
				.cancel::after {
					content: "";
					width: 30px;
					height: 30px;
					background: transparent url("../../assets/icons/cancel.svg") no-repeat 3px 0px;
					left: 5px;
					position: absolute;
					display: inline-block;
				}
			}
			.img-wrapper {
				img {
					margin-bottom: 15px;
					height: 120px;
				}
				div {
					font-size: 19px;
					text-align: center;
				}
			}
			.img-wrapper,
			.text-wrapper {
				display: inline-block;
				width: 230px;
			}
			.text-wrapper {
				vertical-align: text-top;
				div {
					font-size: 16px;
					text-align: center;
					width: 75%;
					margin: 0 auto;
				}
			}
			.note {
				width: 100%;
				text-align: center;
				padding: 10px;
				border: 1.5px solid #ffd54e;
				border-radius: 10px;
				max-width: 735px;
				margin: 0 auto;
				margin-top: 70px;
			}
		}
	}
	 ::-webkit-scrollbar {
		display: none;
	}
}

@media only screen and (min-width:1281px) and (max-width:1366px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				max-width: 1200px;
				//  height: calc(100vh - 200px) !important;
			}
		}
	}
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				max-width: 920px;
			}
		}
	}
}

@media only screen and (min-width:993px) and (max-width:1024px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				max-width: 850px;
			}
		}
	}
}

@media only screen and (min-width:768px) and (max-width:992px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				max-width: 720px;
				.img-wrapper {
					width: 200px;
				}
				.text-wrapper {
					width: 200px;
				}
			}
		}
		.imgVirus {
			width: 370px !important;
		}
	}
}

@media only screen and (min-width:660px) and (max-width:767px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				max-width: 580px;
				.img-wrapper {
					width: 170px;
				}
				.text-wrapper {
					width: 170px;
				}
			}
		}
		.imgTop {
			width: 450px !important;
		}
		.imgVirus {
			width: 370px !important;
		}
	}
}

@media only screen and (min-width:500px) and (max-width:661px) {
	.cover-main-wrapper {
		.main-Section {
			.box {
				.inner-wrapper {
					padding: 80px 20px;
				}
				max-width: 480px;
				.img-wrapper {
					width: 120px;
					margin-right: 15px;
					&:last-child {
						margin-right: 0;
					}
				}
				.text-wrapper {
					width: 120px;
					margin-right: 15px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.imgTop {
			width: 450px !important;
		}
		.imgVirus {
			width: 250px !important;
			height: 250px !important;
			bottom: -35px !important;
		}
		.imgBottom {
			width: 250px !important;
		}
	}
}

@media only screen and (min-width:400px) and (max-width:501px) {
	.cover-main-wrapper {
		height: calc(100vh - 175px) !important;
		.main-Section {
			.box {
				   max-height: calc(100vh - 190px) !important;
				.inner-wrapper {
					padding: 80px 20px;
				}
				max-width: 370px;
				.img-wrapper {
					width: 80px;
					margin-right: 30px;
					&:last-child {
						margin-right: 0;
					}
					img {
						width: 100%;
					}
				}
				.text-wrapper {
					width: 80px;
					margin-right: 30px;
					&.last-child {
						margin-right: 0 !important;
					}
					div {
						font-size: 14px;
						width: auto;
					}
				}
			}
		}
		.imgVirus {
			width: 250px !important;
			height: 250px !important;
			bottom: -35px !important;
		}
		.imgBottom {
			width: 250px !important;
		}
	}
}

@media only screen and (min-width:360px) and (max-width:401px) {
	.cover-main-wrapper {
			height: calc(100vh - 175px) !important;
		.main-Section {
			.box {
				// position: fixed;
				   //  width: 340px;
				       max-height: calc(100vh - 190px) !important;
				.inner-wrapper {
					padding: 80px 20px;
				}
				max-width: 340px;
				.img-wrapper {
					width: 80px;
					margin-right: 30px;
					&:last-child {
						margin-right: 0;
					}
					img {
						width: 100%;
					}
				}
				.text-wrapper {
					width: 80px;
					margin-right: 30px;
					&.last-child {
						margin-right: 0 !important;
					}
					div {
						font-size: 14px;
						width: auto;
					}
				}
			}
		}
		.imgTop {
			width: 320px !important;
		}
		.imgVirus {
			width: 200px !important;
			height: 200px !important;
			bottom: -25px !important;
		}
		.imgBottom {
			width: 180px !important;
		}
	}
}